import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
	enableProdMode();
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch((err) => console.log(err));

// Add custom script tags
const body = document.querySelector('body');

// Load Authroize.net's Accept.js library
const authorizeScriptEl = document.createElement('script');

if (environment.production) {
	authorizeScriptEl.src = 'https://js.authorize.net/v1/Accept.js';
} else {
	authorizeScriptEl.src = 'https://jstest.authorize.net/v1/Accept.js';
}
body.appendChild(authorizeScriptEl);
