import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
if (environment.production) {
    enableProdMode();
}
__NgCli_bootstrap_2.platformBrowser()
    .bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory)
    .catch(function (err) { return console.log(err); });
// Add custom script tags
var body = document.querySelector('body');
// Load Authroize.net's Accept.js library
var authorizeScriptEl = document.createElement('script');
if (environment.production) {
    authorizeScriptEl.src = 'https://js.authorize.net/v1/Accept.js';
}
else {
    authorizeScriptEl.src = 'https://jstest.authorize.net/v1/Accept.js';
}
body.appendChild(authorizeScriptEl);
